import { render, staticRenderFns } from "./PaymentMethods.vue?vue&type=template&id=72b6c804&scoped=true&"
import script from "./PaymentMethods.vue?vue&type=script&lang=js&"
export * from "./PaymentMethods.vue?vue&type=script&lang=js&"
import style0 from "./PaymentMethods.vue?vue&type=style&index=0&id=72b6c804&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b6c804",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBreadcrumbs,VCard,VCheckbox,VCol,VRow,VSelect})
